<template>
  <div>
    <a-card title="收货入库">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回
      </a-button>
      <a-spin :spinning="createLoading">
        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="receiptRecordMaterialItems"
            :loading="materialLoading"
            :pagination="false"
            :scroll="{ x: 2000 }"
          >
            <div slot="receipt_quantity" slot-scope="value, item">
              <a-input-number
                v-model="item.receipt_quantity"
                size="small"
                :max="item.remain_quantity"
                @change="changeQuantity(item)"
              />
            </div>
            <div slot="status" slot-scope="value, item">
              <a-select v-model="item.status" size="small" style="width: 100px">
                <a-select-option key="qualified" value="qualified">良品</a-select-option>
                <a-select-option key="unqualified" value="unqualified">不良品</a-select-option>
              </a-select>
            </div>
            <div slot="batch_number" slot-scope="value, item, index">
              <a-row gutter="4">
                <a-col :span="20">
                  <a-input v-model="item.batch_number" :disabled="!item.enable_batch_control" size="small"></a-input>
                </a-col>
                <a-col :span="4">
                  <a-button type="primary" icon="plus" size="small" @click="addBatch(item, index)"></a-button>
                </a-col>
              </a-row>
            </div>
            <div slot="charge_value" slot-scope="value, item">
              <a-input-number v-model="item.charge_value" size="small" />
            </div>
            <div slot="location" slot-scope="value, item">
              <location-search-select
                v-model="item.location"
                placeholder="请输入或选择库位"
                :warehouse="$route.query.warehouse"
                :area="$route.query.area"
                size="small"
              >
              </location-search-select>
            </div>

            <div slot="production_date" slot-scope="value, item">
              <div v-if="item.enable_shelf_life">
                <a-date-picker v-model="item.production_date" size="small" valueFormat="YYYY-MM-DD" />
              </div>
            </div>
            <div slot="action" slot-scope="value, item, index">
              <a-button-group size="small">
                <a-button type="danger" @click="removeMaterial(index)">删除</a-button>
              </a-button-group>
            </div>
          </a-table>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="createLoading">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { receiptMaterialOption } from "@/api/option";
import { quickReceipt } from "@/api/stockIn";
import { columns } from "./columns";
import { rules } from "./rules";
import moment from "moment";
import NP from "number-precision";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/index"),
  },
  data() {
    return {
      rules,
      moment,
      columns,
      form: {},
      receiptOrder: undefined,
      receiptRecordMaterialItems: [],
      createLoading: false,
      materialLoading: false,
    };
  },
  methods: {
    initData() {
      this.resetForm();

      this.receiptOrder = this.$route.query.receipt_order;
      this.materialLoading = true;

      receiptMaterialOption({ receipt_order: this.receiptOrder, is_completed: false, page_size: 999999 })
        .then((data) => {
          this.getDatas(data.results);
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    async getDatas(datas) {
      await Promise.all(
        datas.map(async (item) => {
          this.receiptRecordMaterialItems.push({
            ...item,
            id: item.id,
            receipt_material: item.id,
            material: item.material,
            material_name: item.material_name,
            material_number: item.material_number,
            unit: item.unit,
            charge_unit: item.charge_unit,
            enable_shelf_life: item.enable_shelf_life,
            shelf_life_days: item.shelf_life_days,
            remain_quantity: item.remain_quantity,
            receipt_quantity: item.remain_quantity,
            charge_value: this.getChargeValue(item, item.remain_quantity),
            total_quantity: item.total_quantity,
            production_date: undefined,
            batch_number: item.batch_number,
            status: "qualified",
            enable_batch_control: item.enable_batch_control,
          });
        })
      );
    },
    removeMaterial(index) {
      let receiptRecordMaterialItems = [...this.receiptRecordMaterialItems];
      receiptRecordMaterialItems.splice(index, 1);
      this.receiptRecordMaterialItems = [...receiptRecordMaterialItems];
    },
    create() {
      if (this.receiptRecordMaterialItems.length == 0) {
        this.$message.warn("未添加产品");
        return;
      }

      if (!this.validateMaterial()) {
        return;
      }

      let formData = {
        id: this.receiptOrder,
        receipt_material_items: this.receiptRecordMaterialItems,
      };

      this.createLoading = true;
      quickReceipt(formData)
        .then((data) => {
          this.$message.success("创建成功");
          this.resetForm();
          this.$router.go(-1);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    resetForm() {
      this.form = {};
      this.receiptRecordMaterialItems = [];
    },
    validateMaterial() {
      for (let item of this.receiptRecordMaterialItems) {
        if (!item.location) {
          this.$message.warn(`产品[${item.material_name}] 未选择库位`);
          return false;
        }

        if (item.receipt_quantity > item.remain_quantity) {
          this.$message.warn(`产品[${item.material_name}] 收货数量不能大于剩余数量`);
          return false;
        }

        if (!item.batch_number || this.batch_number == "") {
          this.$message.warn(`产品[${item.material_name}] 请输入批次号`);
          return false;
        }

        if (item.enable_shelf_life && !item.production_date) {
          this.$message.warn(`产品[${item.material_name}] 请选择生产日期`);
          return false;
        }
      }

      return true;
    },
    addBatch(item, index) {
      let new_item = { ...item };
      new_item["batch_number"] = item.enable_batch_control ? `B${moment().format("YYYYMMDDHHmm")}` : "N/A";
      this.receiptRecordMaterialItems.splice(index, 0, new_item);
    },
    getChargeValue(item, quantity) {
      if (item.charge_unit === "quantity") {
        return quantity || 0;
      } else if (item.charge_unit === "ton") {
        return NP.times(quantity, item.net_weight || 0, 0.001);
      } else if (item.charge_unit === "cube") {
        return NP.times(quantity, item.volume || 0);
      }
      return 0;
    },
    changeQuantity(item) {
      item.charge_value = this.getChargeValue(item, item.receipt_quantity);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
